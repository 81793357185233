import styles from './footer.module.scss'
import google from '../../shared/images/GooglePlayBtn.svg'
import apple from '../../shared/images/AppStoreBtn.png'
import pdf from './13_8_2024_Публичная_оферта_.pdf'
import pdf2 from './Порядок_проведения_оплаты_по_банковским_картам.pdf'

export const Footer = () => {
    return (
        <footer className={styles.body}>
            <div className="container">
                <div className={styles.wrapper}>
                    <div className={styles.left}>
                        <img src="" alt=""/>
                        <div className={styles.links}>
                            <p>Реквизиты компании</p>
                            <p>ИП Фролов Вячеслав Юрьевич</p>
                            <p>ОГРН 315774600280285</p>
                            <p>ИНН 771993128182</p>
                            <p>+7 905 572 70 68</p>
                            <p>frollavyaches@gmail.com</p>
                            <div className={styles.dataLinks}>
                                <a target='_blank' href={pdf}>Договор оферты</a>
                                <a target='_blank' href={pdf2}>Порядок проведения оплаты</a>
                            </div>
                            
                        </div>
                        <p className={styles.copyright}>© 2024 Alfateam - be the best with us</p>
                    </div>
                    <div className={styles.linksToApplications}>
                        <span>Get the app</span>
                        <div className={styles.actions}>
                            <a href="#">
                                <img src={google} alt=""/>
                            </a>
                            <a href="#">
                                <img src={apple} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )
}